import { Button, Card } from 'antd';
import React from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { removeLink, removeNode } from '../../actions/graph-modals';
import { Dispatch, func, LinkWithNodes, Node, store } from '../../utils/types';
import { CloseCircleOutlined, DragOutlined } from '@ant-design/icons';
import { fieldExplanationMap } from '../../utils/helpers';
import { getInfoContent } from '../../utils/table-columns';
import { useHistory } from 'react-router-dom';
import {
  patchCurrentQueryDetiails,
  queryWithCurrentQueryDetails,
} from '../../actions/query';

type Props = {
  nodes: Node[];
  links: LinkWithNodes[];
  removeNodeAction: func;
  removeLinkAction: func;
  analytes: string[];
  patchCurrentQueryDetailsAction: func;
  queryWithCurrentQueryDetailsAction: func;
};
const GraphInformationModal = ({
  nodes,
  links,
  removeNodeAction,
  removeLinkAction,
  analytes,
  patchCurrentQueryDetailsAction,
  queryWithCurrentQueryDetailsAction,
}: Props) => {
  const history = useHistory();

  const handleAdd = (node: Node) => {
    patchCurrentQueryDetailsAction({
      analytes: [...analytes, node.symbol],
    });
    queryWithCurrentQueryDetailsAction(history);
  };

  const handleRemove = (node: Node) => {
    patchCurrentQueryDetailsAction({
      analytes: analytes.filter((symbol: string) => symbol !== node.symbol),
    });
    queryWithCurrentQueryDetailsAction(history);
  };

  return (
    <>
      {nodes.map((node) => (
        <Draggable
          key={node.id}
          defaultPosition={{
            x: 40 + nodes.length * 10,
            y: 40 + nodes.length * 10,
          }}
          position={undefined}
          handle=".handle"
          scale={1}
        >
          <Card
            title={node.symbol}
            extra={
              <>
                <span className="handle cursor-drag drag-button">
                  <DragOutlined />
                </span>
                <span
                  className="cursor-pointer close-modal-button"
                  onClick={() => removeNodeAction(node.id)}
                >
                  <CloseCircleOutlined />
                </span>
              </>
            }
            className="graph-node-modal"
          >
            {Object.keys(fieldExplanationMap[node.location]).map((field) => (
              <p key={field}>
                {fieldExplanationMap[node.location][field]} :{' '}
                {field.startsWith('info')
                  ? // @ts-ignore
                    getInfoContent(node.location, field, node[field])
                  : // @ts-ignore
                    node[field]}
              </p>
            ))}

            <div className="justify-end">
              {analytes.some((symbol: string) => symbol === node.symbol) ? (
                <Button onClick={() => handleRemove(node)}>REMOVE</Button>
              ) : (
                <Button onClick={() => handleAdd(node)}>ADD</Button>
              )}
            </div>
          </Card>
        </Draggable>
      ))}

      {links.map((link) => (
        <Draggable
          key={link.source.id + '' + link.target.id}
          defaultPosition={{
            x: 80 + links.length * 10,
            y: 80 + links.length * 10,
          }}
          position={undefined}
          handle=".linkhandle"
          scale={1}
        >
          <Card
            title={link.source.symbol + ' --- ' + link.target.symbol}
            extra={
              <>
                <span className="linkhandle cursor-drag drag-button">
                  <DragOutlined />
                </span>
                <span
                  className="cursor-pointer close-modal-button"
                  onClick={() =>
                    removeLinkAction(link.source.id, link.target.id)
                  }
                >
                  <CloseCircleOutlined />
                </span>
              </>
            }
            className="graph-node-modal"
          >
            <p>Corr: {link.correlation}</p>
            <p>P-Value: {link.pvalue}</p>
            <p>Adjusted P-Value: {link.padj}</p>
          </Card>
        </Draggable>
      ))}
    </>
  );
};
const mapStateToProps = (state: store) => ({
  nodes: state.graphModals.nodes,
  links: state.graphModals.links,
  analytes: state.query.currentQueryDetails.analytes,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  removeNodeAction: (nodeId: number | string) => dispatch(removeNode(nodeId)),
  removeLinkAction: (source: number | string, target: number | string) =>
    dispatch(removeLink(source, target)),
  patchCurrentQueryDetailsAction: (updatedFields: any) =>
    dispatch(patchCurrentQueryDetiails(updatedFields)),
  queryWithCurrentQueryDetailsAction: (history: any) =>
    dispatch(queryWithCurrentQueryDetails(history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphInformationModal);
