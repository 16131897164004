import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../config/constants';
import { connect } from 'react-redux';
import { Dispatch, func, store } from '../../utils/types';
import { setShowTutorial } from '../../actions/query';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const { Header } = Layout;

type Props = {
  setShowTutorialAction: func;
  firstInitDataTypes: string;
};
const Navbar = ({ setShowTutorialAction, firstInitDataTypes }: Props) => {
  const history = useHistory();
  return (
    <Header>
      <div className="left-header">
        <h2 className="header-title">
          <Link to={baseUrl + '/'}>
            iNetModels: an interactive visualization and database of multi-omics
            data
          </Link>
        </h2>

        <div className="justify-center">
          {firstInitDataTypes === 'success' && (
            <span
              className="cursor-pointer tutorial-button"
              onClick={() => {
                if (window.location.pathname !== '/') {
                  history.push('/');
                }
                setShowTutorialAction(true);
              }}
            >
              <QuestionCircleOutlined />
            </span>
          )}
          <Link className="nav-link-item" to={baseUrl + '/'}>
            Search
          </Link>
          <Link className="nav-link-item" to="/help">
            Help
          </Link>
          <Link className="nav-link-item" to={baseUrl + '/about'}>
            About
          </Link>
          <Link className="nav-link-item" to={baseUrl + '/upload'}>
            Upload
          </Link>
          <Link className="nav-link-item" to={baseUrl + '/inet-api'}>
            API
          </Link>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = (state: store) => ({
  firstInitDataTypes: state.dataTypes.firstInitData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setShowTutorialAction: (showTutorial: boolean) =>
    dispatch(setShowTutorial(showTutorial)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
