import React from 'react';
import { Layout } from 'antd';
import AppRouter from './AppRouter';
import Navbar from './layouts/Navbar';

const App = () => {
  return (
    <>
      <Navbar />
      <Layout className="min-height-100vh">
        <AppRouter />
      </Layout>
    </>
  );
};

export default App;
