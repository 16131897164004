import React, { useState } from 'react';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { connect } from 'react-redux';
import { Dispatch, func, store } from '../../utils/types';
import {
  patchCurrentQueryDetiails,
  queryWithCurrentQueryDetails,
  setShowTutorial,
} from '../../actions/query';
import { useHistory } from 'react-router-dom';
import { getTourSteps } from '../../utils/tour-steps';

const disableBody = (target: any) => disableBodyScroll(target);
const enableBody = (target: any) => enableBodyScroll(target);

type Props = {
  showTutorial: boolean;
  setShowTutorialAction: func;
  patchCurrentQueryDetailsAction: func;
  queryWithCurrentQueryDetailsAction: func;
};

const TourComponent = ({
  showTutorial,
  setShowTutorialAction,
  patchCurrentQueryDetailsAction,
  queryWithCurrentQueryDetailsAction,
}: Props) => {
  const history = useHistory();
  const [goToStep, setGoToStep] = useState(0);
  const steps = getTourSteps(
    patchCurrentQueryDetailsAction,
    queryWithCurrentQueryDetailsAction,
    history,
    setGoToStep
  );

  return (
    <Tour
      steps={steps}
      disableFocusLock={true}
      isOpen={showTutorial}
      onRequestClose={() => setShowTutorialAction(false)}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      showNavigation={false}
      goToStep={goToStep}
      lastStepNextButton="Done! Let's start."
    />
  );
};

const mapStateToProps = (state: store) => ({
  showTutorial: state.query.showTutorial,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setShowTutorialAction: (showTutorial: boolean) =>
    dispatch(setShowTutorial(showTutorial)),
  patchCurrentQueryDetailsAction: (updatedFields: any) =>
    dispatch(patchCurrentQueryDetiails(updatedFields)),
  queryWithCurrentQueryDetailsAction: (history: any) =>
    dispatch(queryWithCurrentQueryDetails(history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TourComponent);
