import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';

const handleSearch = (
  selectedKeys: any,
  confirm: any,
  dataIndex: any,
  setSearchFilter: any
) => {
  confirm();
  setSearchFilter({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

const handleReset = (clearFilters: any, setSearchFilter: any) => {
  clearFilters();
  setSearchFilter((filter: any) => ({ ...filter, searchText: '' }));
};

export const getColumnSearchProps = (
  dataIndex: string,
  searchFilter: any,
  setSearchFilter: any,
  searchInputRef: any,
  extraIndex?: string
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: any) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInputRef}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() =>
          handleSearch(selectedKeys, confirm, dataIndex, setSearchFilter)
        }
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys, confirm, dataIndex, setSearchFilter)
          }
          icon={<></>}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, setSearchFilter)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: any) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : '#000' }} />
  ),
  onFilter: (value: any, record: any) => {
    const val = extraIndex ? record[dataIndex][extraIndex] : record[dataIndex];
    return val
      ? val.toString().toLowerCase().includes(value.toLowerCase())
      : '';
  },
  onFilterDropdownVisibleChange: (visible: boolean) => {
    if (visible) {
      setTimeout(() => searchInputRef.current.select(), 100);
    }
  },
  render: (text: string) =>
    searchFilter.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchFilter.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});
