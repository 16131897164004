import React from 'react';

import { getColumnSearchProps } from './colum-search-props';
import { fieldExplanationMap } from './helpers';
import { LinkWithNodes, Node } from './types';

export const EDGES = (
  searchFilter: any,
  setSearchFilter: any,
  searchInputRef: any
) => [
  {
    title: 'Source',
    key: 'source',
    dataIndex: ['source', 'symbol'],
    sorter: (a: LinkWithNodes, b: LinkWithNodes) =>
      a.source.symbol.localeCompare(b.source.symbol),
    ...getColumnSearchProps(
      'source',
      searchFilter,
      setSearchFilter,
      searchInputRef,
      'symbol'
    ),
  },
  {
    title: 'Target',
    key: 'target',
    dataIndex: ['target', 'symbol'],
    sorter: (a: LinkWithNodes, b: LinkWithNodes) =>
      a.target.symbol.localeCompare(b.target.symbol),
    ...getColumnSearchProps(
      'target',
      searchFilter,
      setSearchFilter,
      searchInputRef,
      'symbol'
    ),
  },
  {
    title: 'Correlation',
    key: 'correlation',
    dataIndex: 'correlation',
    render: (corr: number) => <span>{corr?.toFixed(3)}</span>,
  },
  {
    title: 'Pval',
    key: 'pvalue',
    dataIndex: 'pvalue',
    render: (pval: number) => <span>{pval?.toExponential(3)}</span>,
  },
  {
    title: 'Padj',
    key: 'padj',
    dataIndex: 'padj',
    render: (padj: number) => <span>{padj?.toExponential(3)}</span>,
  },
];

export const getInfoContent = (
  type: string,
  infoType: string,
  payload: any
) => {
  let content: string | JSX.Element = payload;
  switch (type) {
    case 'METABOLITE':
      if (infoType === 'info2' || infoType === 'info3') {
        content = (
          <a
            href={
              (infoType === 'info2'
                ? 'https://www.genome.jp/dbget-bin/www_bget?'
                : 'http://www.hmdb.ca/metabolites/') + payload
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {payload}
          </a>
        );
      }
      break;
    case 'PROTEIN':
      if (infoType === 'info2' || infoType === 'info3') {
        content = (
          <a
            href={
              (infoType === 'info2'
                ? 'https://www.uniprot.org/uniprot/'
                : 'https://www.proteinatlas.org/') + payload
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {payload}
          </a>
        );
      }
      break;
    case 'GENE':
      if (infoType === 'info1') {
        content = (
          <a
            href={'https://www.proteinatlas.org/' + payload}
            target="_blank"
            rel="noopener noreferrer"
          >
            {payload}
          </a>
        );
      }
      break;
  }
  return content;
};

export const analyteColumnCreator = (
  analyteType: string,
  searchFilter: any,
  setSearchFilter: any,
  searchInputRef: any
) => {
  return [
    {
      title: fieldExplanationMap[analyteType].symbol,
      key: 'symbol',
      dataIndex: 'symbol',
      sorter: (a: Node, b: Node) => a.symbol?.localeCompare(b.symbol),
      ...getColumnSearchProps(
        'symbol',
        searchFilter,
        setSearchFilter,
        searchInputRef
      ),
    },
    ...(fieldExplanationMap[analyteType].info1
      ? [
          {
            title: fieldExplanationMap[analyteType].info1,
            key: 'info1',
            dataIndex: 'info1',
            sorter: (a: Node, b: Node) => a.info1?.localeCompare(b.info1),
            render: (text: any) => getInfoContent(analyteType, 'info1', text),
          },
        ]
      : []),
    ...(fieldExplanationMap[analyteType].info2
      ? [
          {
            title: fieldExplanationMap[analyteType].info2,
            key: 'info2',
            dataIndex: 'info2',
            sorter: (a: Node, b: Node) => a.info2?.localeCompare(b.info2),
            render: (text: any) => getInfoContent(analyteType, 'info2', text),
          },
        ]
      : []),
    ...(fieldExplanationMap[analyteType].info3
      ? [
          {
            title: fieldExplanationMap[analyteType].info3,
            key: 'info3',
            dataIndex: 'info3',
            sorter: (a: Node, b: Node) => a.info3?.localeCompare(b.info3),
            render: (text: any) => getInfoContent(analyteType, 'info3', text),
          },
        ]
      : []),
    {
      title: fieldExplanationMap[analyteType].location,
      key: 'location',
      dataIndex: 'location',
      sorter: (a: Node, b: Node) => a.location?.localeCompare(b.location),
    },
    {
      title: fieldExplanationMap[analyteType].cluster,
      key: 'cluster',
      dataIndex: 'cluster',
    },
  ];
};
