import { CategoryDataTypeMap } from '../utils/types';

const dataTypesDefaultState = {
  firstInitData: 'init',
  allDataTypes: [],
  catDataTypeMaps: {},
};

export default (
  state = dataTypesDefaultState,
  action: {
    type: string;
    allDataTypes: string[];
    categoryDataTypeMap: CategoryDataTypeMap;
  }
) => {
  switch (action.type) {
    case 'data-types/SET_DATA_TYPES':
      return {
        ...state,
        firstInitData: 'success',
        allDataTypes: action.allDataTypes,
        catDataTypeMaps: action.categoryDataTypeMap,
      };
    case 'data-types/SET_FAIL':
      return {
        ...state,
        firstInitData: 'fail',
      };
    default:
      return state;
  }
};
