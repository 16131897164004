import { Card } from 'antd';
import React from 'react';
const AboutPage = () => {
  return (
    <Card className="margin-5r explanation-texts">
      <div>
        <h2>About Inetmodels</h2>
        <p>
          Inetmodels is a database and visualization tool for multiple
          biological networks, that includes different omics-data, run by
          <a
            href="http://sysmedicine.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sysmedicine group, SciLifeLab, KTH, Sweden
          </a>
          and
          <a
            href="https://bashbio.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bash Biotech
          </a>
          as part of our recent studies. Molecular and Clinical data used in the
          networks were based on publicly available data from multiple studies
          (see data source citation below).{' '}
          <b>
            This website is available freely for all users without any login
            requirement.
          </b>
        </p>
        <p>
          Here, users can explore the functionally or physically associated
          analytes (genes, proteins, metabolites, microbes, and clinical
          variables) from given networks and test their biological hypothesis
          and develop efficient treatment strategies. This tool has multiple
          features that can help users with their experiences, including:
        </p>
        <ol>
          <li>Fast querying of more than 1 analytes (up to 5 analytes). </li>
          <li>Dynamic and interactive visualization of the network.</li>
          <li>
            Fully customizable network, including analyte selections and
            statistical value adjustments
          </li>
          <li>
            Interconnectivity with other databases, such as Human Protein Atlas,
            KEGG, and Uniprot.
          </li>
          <li>
            Downloadable networks, including their visualizations and specific
            network informations (including nodes and edges information)
          </li>
          <li>
            Compatibility with other well-known network tools, such as
            Cytoscape, NetworkX, and iGraph
          </li>
          <li>Ease of sharing by just copying the link </li>
          <li>
            <a
              href="https://github.com/muharif/Inetmodels/tree/main/API"
              target="_blank"
              rel="noopener noreferrer"
            >
              API
            </a>
            to access the database programmatically.
          </li>
        </ol>
        <p>
          This database contains more than 100 networks, including gene
          co-expression networks from tissue and cancer and multi-omics networks
          (longitudinal and delta networks) from multiple welness studies. We
          wish that our database will greatly benefit research community in
          biology and medicine.
        </p>
        To those who consider to use our database, please cite papers below:
        <ol>
          <li>
            Muhammad Arif<sup>#</sup>, Cheng Zhang<sup>#</sup>, Xiangyu Li, Cem
            Güngör, Buğra Çakmak, Metin Arslantürk, Abdellah Tebani, Berkay
            Özcan, Oğuzhan Subaş, Wenyu Zhou, Brian Piening, Hasan Turkez, Linn
            Fagerberg, Nathan Price, Leroy Hood, Michael Snyder, Jens Nielsen,
            Mathias Uhlen, Adil Mardinoglu, (2021), iNetModels 2.0: an
            interactive visualization and database of multi-omics data, Nucleic
            Acids Research.{' '}
            <a
              href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab254/6225230"
              target="_blank"
              className="no-margin-horizontal"
              rel="noopener noreferrer"
            >
              LINK
            </a>
          </li>
          <li>
            Sunjae Lee<sup>#</sup>, Cheng Zhang<sup>#</sup>, Muhammad Arif
            <sup>#</sup>, Rui Benfeitas, Gholamreza Bidkhori, Zhengtao Liu, Rui
            Benfetias, Gholamreza Bidkhori, Sumit Deshmukh, Mohamed Al Shobky,
            Alen Lovric, Jan Boren, Jens Nielsen, Mathias Uhlen, Adil
            Mardinoglu, (2017), TCSBN: a database of tissue and cancer specific
            biological networks, Nucleic Acids Research.{' '}
            <a
              href="https://academic.oup.com/nar/article/doi
/10.1093/nar/gkx994/4563312/TCSBN-a-database-of-tissue-and-cancer-specific?guestAccessKey=fc04bec9-9c98-410d-9103-e98cdc82ff77"
              target="_blank"
              className="no-margin-horizontal"
              rel="noopener noreferrer"
            >
              LINK
            </a>
          </li>
        </ol>
        <div>
          Moreover, please cite the data provider of the relevant networks that
          you use:
          <ol>
            <li>
              Normal Networks: Follow GTEx citation Guideline.
              <a
                href="https://gtexportal.org/home/faq#citePortal"
                target="_blank"
                rel="noopener noreferrer"
              >
                LINK
              </a>
            </li>
            <li>
              Cancer Networks: Follow TCGA citation Guideline.
              <a
                href="https://www.cancer.gov/about-nci/organization/ccg/research/structural-genomics/tcga/using-tcga/citing-tcga"
                target="_blank"
                rel="noopener noreferrer"
              >
                LINK
              </a>
            </li>
            <li>
              Scapis - SciLifeLab Wellness Profiling: Abdellah Tebani, Anders
              Gummesson, Wen Zhong, Ina Schuppe Koistinen, Tadepally
              Lakshmikanth, Lisa M Olsson, Fredrik Boulund, Maja Neiman, Hans
              Stenlund, Cecilia Hellström, Max J Karlsson, Muhammad Arif, Tea
              Dodig-Crnković, Adil Mardinoglu, Sunjae Lee, Cheng Zhang, Yang
              Chen, Axel Olin, Jaromir Mikes, Hanna Danielsson, Kalle von
              Feilitzen, Per-Anders Jansson, Oskar Angerås, Mikael Huss, Sanela
              Kjellqvist, Jacob Odeberg, Fredrik Edfors, Valentina Tremaroli,
              Björn Forsström, Jochen M Schwenk, Peter Nilsson, Thomas Moritz,
              Fredrik Bäckhed, Lars Engstrand, Petter Brodin, Göran Bergström,
              Mathias Uhlen, Linn Fagerberg, (2020), Integration of molecular
              profiles in a longitudinal wellness profiling cohort, Nature
              communications.
              <a
                href="https://www.nature.com/articles/s41467-020-18148-7"
                target="_blank"
                rel="noopener noreferrer"
              >
                LINK
              </a>
            </li>
            <li>
              P100: Nathan D. Price, Andrew T. Magis, John C. Earls, Gustavo
              Glusman, Roie Levy, Christopher Lausted, Daniel T. McDonald,
              Ulrike Kusebauch, Christopher L. Moss, Yong Zhou, Shizhen Qin,
              Robert L. Moritz, Kristin Brogaard, Gilbert S. Omenn, Jennifer C.
              Lovejoy, and Leroy Hood, (2017), A wellness study of 108
              individuals using personal, dense, dynamic data clouds. Nature
              Biotechnology.
              <a
                href="https://www.nature.com/articles/nbt.3870"
                target="_blank"
                rel="noopener noreferrer"
              >
                LINK
              </a>
            </li>
            <li>
              Integrative Personal Omics: Brian D Piening, Wenyu Zhou, Kévin
              Contrepois, Hannes Röst, Gucci Jijuan Gu Urban, Tejaswini Mishra,
              Blake M Hanson, Eddy J Bautista, Shana Leopold, Christine Y Yeh,
              Daniel Spakowicz, Imon Banerjee, Cynthia Chen, Kimberly Kukurba,
              Dalia Perelman, Colleen Craig, Elizabeth Colbert, Denis Salins,
              Shannon Rego, Sunjae Lee, Cheng Zhang, Jessica Wheeler, M Reza
              Sailani, Liang Liang, Charles Abbott, Mark Gerstein, Adil
              Mardinoglu, Ulf Smith, Daniel L Rubin, Sharon Pitteri, Erica
              Sodergren, Tracey L McLaughlin, George M Weinstock, Michael P
              Snyder, (2018), Integrative personal omics profiles during periods
              of weight gain and loss. Cell systems.
              <a
                href="https://www.cell.com/cell-systems/fulltext/S2405-4712(17)30555-0?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2405471217305550%3Fshowall%3Dtrue"
                target="_blank"
                rel="noopener noreferrer"
              >
                LINK
              </a>
            </li>
          </ol>
          <p>
            We are also accepting user submitted network for their own studies
            to be included in our platform. Below are the list of the user
            submitted networks that are currently available:
          </p>
          <ol>
            <li>
              Covid-19 CMA Supplementation: Ozlem Altay, Muhammad Arif, Xiangyu
              Li, Hong Yang, Mehtap Aydın, Gizem Alkurt, L. Nilsun Altunal,
              Woonghee Kim, Dogukan Akyol, Cheng Zhang, Gizem Dinler-Doganay,
              Hasan Turkez, Saeed Shoaie, Jens Nielsen, Jan Borén, Levent
              Doganay, Mathias Uhlén, Adil Mardinoglu (2021). SUBMITTED
              (contact: adilm [at] scilifelab [dot] se)
            </li>
            <li>
              NAFLD CMA Supplementation: Mujdat Zeybel, Hong Yang, Ozlem Altay,
              Muhammad Arif, Claudia Fredolini, Murat Akyildiz, Burcin Saglam,
              Mehmet Gokhan Gonenli, Dilek Ural, Woonghee Kim, Xiangyu Li,
              Jochen M. Schwenk, Cheng Zhang, Saeed Shoaie, Jens Nielsen,
              Mathias Uhlén, Jan Borén, Adil Mardinoglu (2021). SUBMITTED
              (contact: adilm [at] scilifelab [dot] se)
            </li>
            <li>
              NAFLD Baseline Study: Mujdat Zeybel, Muhammad Arif, Xiangyu Li,
              Ozlem Altay, Murat Akyildiz, Burcin Saglam, Mehmet Gokhan Gonenli,
              Buket Yigit, Burge Ulukan, Dilek Ural, Saeed Shoaie, Hasan Turkez,
              Jens Nielsen, Cheng Zhang, Mathias Uhlén, Jan Borén, Adil
              Mardinoglu (2021). SUBMITTED (contact: adilm [at] scilifelab [dot]
              se)
            </li>
          </ol>
        </div>
        <h2>Contact</h2>
        <p>
          Please contact Muhammad Arif (muhammad.arif [at] scilifelab.se) for
          further details.
        </p>
        <h2>Data Availability</h2>
        <p>
          All raw network data presented on this platform can be downloaded via
          this
          <a
            href="https://inetmodels.com/files/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LINK
          </a>
        </p>
      </div>
    </Card>
  );
};

export default AboutPage;
