import { QueryResponse } from './types';

// We are also using the keys in this object
// Be careful if you think delete or update
const allFields: any = {
  ALL: {
    symbol: 'Name',
    location: 'Analyte Type',
    cluster: 'Cluster',
  },
  GENE: {
    symbol: 'Gene Symbol',
    info1: 'Gene ID',
    info2: 'Mean expr.',
    info3: 'Expr. Range',
    location: 'Analyte Type',
    cluster: 'Cluster',
    color: '#018cff',
    colorOnHover: '#75c1ff',
  },
  METABOLITE: {
    symbol: 'Metabolite Name',
    info2: 'KEGG ID',
    info3: 'HMDB ID',
    location: 'Analyte Type',
    cluster: 'Cluster',
    color: '#CC0066',
    colorOnHover: '#e86dab',
  },
  PROTEIN: {
    symbol: 'Protein Name',
    info1: 'Description',
    info2: 'Uniprot ID',
    info3: 'Ensembl ID',
    location: 'Analyte Type',
    cluster: 'Cluster',
    color: '#00CC99',
    colorOnHover: '#96e3d0',
  },
  'ORAL MICROBIOME': {
    symbol: 'Family',
    info1: 'Order',
    info2: 'Class',
    info3: 'Phylum',
    location: 'Analyte Type',
    cluster: 'Cluster',
    color: '#46469E',
    colorOnHover: '#6565b5',
  },
  'GUT MICROBIOME': {
    symbol: 'Family',
    info1: 'Order',
    info2: 'Class',
    info3: 'Phylum',
    location: 'Analyte Type',
    cluster: 'Cluster',
    color: '#9999FF',
    colorOnHover: '#cfc9ff',
  },
  CLINICAL: {
    symbol: 'Short Name',
    info1: 'Description',
    info2: 'Unit',
    info3: 'Biological Matrix',
    location: 'Analyte Type',
    cluster: 'Cluster',
    color: '#FF6699',
    colorOnHover: '#ffa6c3',
  },
};

export const fieldExplanationMap: any = Object.keys(allFields).reduce(
  (acc: any, curr) => {
    acc[curr] = { ...allFields[curr] };
    delete acc[curr].color;
    delete acc[curr].colorOnHover;
    return acc;
  },
  {}
);

export const linkColors: any = {
  'NEGATIVE CORRELATION': '#3bc7ed',
  'ZERO CORRELATION': '#a8a8a8',
  'POSITIVE CORRELATION': '#fca390',
};

export const colorSchema = (location: string) => {
  return allFields[location] && allFields[location].colorOnHover
    ? allFields[location].color
    : '#000';
};

export const colorSchemaHover = (location: string) => {
  return allFields[location] && allFields[location].colorOnHover
    ? allFields[location].colorOnHover
    : '#000';
};

export const includeNodesToLinks = (data: QueryResponse) => {
  data.links.forEach((link: any) => {
    link.source = data.nodes.find((node) => node.id === +link.source);
    link.target = data.nodes.find((node) => node.id === +link.target);
  });
};
