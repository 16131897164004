// @ts-nocheck
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import { connect } from 'react-redux';
import { addLink, addNode } from '../../actions/graph-modals';
import { colorSchema, linkColors } from '../../utils/helpers';
import { colorSchemaHover } from '../../utils/helpers';
import {
  Dispatch,
  func,
  Node,
  QueryResponse,
  store,
  LinkWithNodes,
  QueryRequest,
} from '../../utils/types';
import { Switch } from 'antd';
import GraphInformationModal from './GraphInformationModal';
import * as d3 from 'd3';

type Props = {
  requestedQueryDetails: QueryRequest;
  queryResponse: QueryResponse;
  addNodeAction: func;
  addLinkAction: func;
};

const DrawGraph = ({
  queryResponse,
  requestedQueryDetails,
  addNodeAction,
  addLinkAction,
}: Props) => {
  const [sizes, setSizes] = useState({
    height: window.innerHeight - 130,
    width: window.innerWidth - 200,
  });

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const [areLinksVisible, setAreLinksVisible] = useState(false);

  const fgRef = useRef();
  const nodeClickHandler = useCallback(
    (node: any) => {
      addNodeAction(node);
    },
    [addNodeAction]
  );

  const linkClickHandler = useCallback(
    (link: any) => {
      addLinkAction(link);
    },
    [addLinkAction]
  );

  useEffect(() => {
    fgRef.current?.d3Force(
      'x',
      d3
        .forceX(function (d: any) {
          return 0;
        })
        .strength(0.2)
    );

    fgRef.current?.d3Force(
      'y',
      d3
        .forceY(function (d: any) {
          return 0;
        })
        .strength(0.2)
    );

    //fgRef.current?.d3Force('collide', d3.forceCollide(14).iterations(25));
    fgRef.current?.d3Force(
      'manyBody',
      d3.forceManyBody().strength([-100]).theta([0])
    );

    const resizeHandler = () => {
      setSizes({
        height: window.innerHeight - 130,
        width: window.innerWidth - 200,
      });
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fgRef.current?.zoomToFit(1000, 100);
    }, 1000);
  }, [queryResponse]);

  useEffect(() => {
    if (
      queryResponse.links.length < 700 ||
      requestedQueryDetails.firstNeighbour
    ) {
      setAreLinksVisible(true);
    }
  }, [queryResponse, requestedQueryDetails.firstNeighbour, setAreLinksVisible]);

  const data = useMemo(() => {
    // cross-link node objects
    queryResponse.links.forEach((link) => {
      const a = queryResponse.nodes.find((node) => node.id === +link.source);
      const b = queryResponse.nodes.find((node) => node.id === +link.target);
      !a?.neighbors && (a.neighbors = []);
      !b?.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });
    return queryResponse;
  }, [queryResponse]);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleNodeHover = (node) => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      node.neighbors.forEach((neighbor) => highlightNodes.add(neighbor));
      node.links.forEach((link) => highlightLinks.add(link));
    }

    setHoverNode(node || null);
    updateHighlight();
  };

  const handleLinkHover = (link) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  };

  return (
    <>
      <div className="graph-wrapper">
        {data?.nodes.length > 0 ? (
          <>
            <div className="justify-end show-links-wrapper">
              <div>
                Show Links
                <Switch
                  checked={areLinksVisible}
                  className="margin-left-1r"
                  onChange={(checked: boolean) => setAreLinksVisible(checked)}
                />
              </div>
            </div>
            <ForceGraph2D
              graphData={data}
              width={sizes.width}
              height={sizes.height}
              ref={fgRef}
              nodeLabel="symbol"
              linkLabel={(link: LinkWithNodes) => {
                return `<div class="link-hover-info"><p>${link.source.symbol} --- ${link.target.symbol}</p>
                        <p>Corr.: ${link.correlation} </p>
                        <p>P-Val.: ${link.pvalue} </p>
                        <p>Adj. P-Val.: ${link.padj} </p></div>`;
              }}
              onNodeClick={nodeClickHandler}
              onLinkClick={linkClickHandler}
              onNodeDragEnd={(node) => {
                node.fx = node.x;
                node.fy = node.y;
              }}
              nodeCanvasObject={(
                node: {
                  x: any;
                  y: any;
                  id: any;
                  symbol: string;
                  location: any;
                },
                ctx: any
              ) => {
                const { id, x, y, symbol, location } = node;
                ctx.fillStyle =
                  id === hoverNode?.id || highlightNodes.has(node)
                    ? colorSchemaHover(location)
                    : requestedQueryDetails.clustering
                    ? colorSchema(location)
                    : '#000';

                ctx.beginPath();
                ctx.arc(x, y, 5, 0, 2 * Math.PI, false);

                ctx.strokeStyle = 'rgba(92, 92, 92, 0.3)';
                ctx.lineWidth = 1;
                ctx.stroke();
                ctx.fill();

                if (
                  requestedQueryDetails.analytes.some((gene) => gene === symbol)
                ) {
                  ctx.font = 'bold 6.5px Helvetica';
                  ctx.textAlign = 'center';
                  ctx.fillStyle = '#4d4d4d';
                  ctx.fillText(symbol, x, y - 6);
                } else {
                  ctx.font = '4px Helvetica';
                  ctx.textAlign = 'center';
                  ctx.fillStyle = '#404040';
                  ctx.fillText(symbol, x, y - 6);
                }
              }}
              linkColor={(d) => {
                if (d.correlation < 0) {
                  return linkColors['NEGATIVE CORRELATION'];
                } else if (d.correlation === 0) {
                  return linkColors['ZERO CORRELATION'];
                } else {
                  return linkColors['POSITIVE CORRELATION'];
                }
              }}
              linkWidth={(link) => (highlightLinks.has(link) ? 6 : 2)}
              onNodeHover={handleNodeHover}
              onLinkHover={handleLinkHover}
              linkVisibility={areLinksVisible}
            />
            <GraphInformationModal />
          </>
        ) : (
          <>
            <div className="not-found-wrapper">Could not found any node!</div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: store) => ({
  requestedQueryDetails: state.query.requestedQueryDetails,
  queryResponse: state.query.queryResponse,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addNodeAction: (node: Node) => dispatch(addNode(node)),
  addLinkAction: (link: LinkWithNodes) => dispatch(addLink(link)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawGraph);
