import React, { useState } from 'react';
import { Layout, Row } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import QueryArea from '../common-components/QueryArea';
const { Sider } = Layout;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSider = () => {
    setCollapsed((collapse) => !collapse);
  };
  return (
    <Sider collapsed={collapsed} className="padding-top-0-5r">
      {collapsed && (
        <Row
          justify="end"
          className="align-baseline margin-top-0-5r padding-right-10p"
        >
          <span onClick={toggleSider}>
            <DoubleRightOutlined />
          </span>
        </Row>
      )}

      <div className={collapsed ? 'hidden-query-area' : 'show-query-area'}>
        <QueryArea toggleSider={toggleSider} />
      </div>
    </Sider>
  );
};

export default Sidebar;
