import { Select } from 'antd';
import React, { useState, useEffect } from 'react';
import useDebounce from '../../hooks/use-debounce';
import { func } from '../../utils/types';
const { Option } = Select;

type Props = {
  disabled: boolean;
  delay: number;
  onChangeFn: func;
  fetchMethod: func;
  fetchMethodExtraArgs: any;
  value?: string[];
};
const DelayedAutoComplete = ({
  disabled,
  delay,
  onChangeFn,
  fetchMethod,
  fetchMethodExtraArgs,
  value,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<string[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, delay);

  useEffect(() => {
    setResults([]);
    if (debouncedSearchTerm) {
      fetchMethod(debouncedSearchTerm, fetchMethodExtraArgs)
        .then((res: any) => {
          if (res.data && res.data.length > 0) {
            setResults(res.data);
          }
        })
        .finally(() => setIsSearching(false));
    } else {
      setIsSearching(false);
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      <Select
        size="large"
        mode="multiple"
        allowClear
        value={value}
        className="w-100"
        dropdownStyle={{
          visibility: debouncedSearchTerm ? 'visible' : 'hidden',
        }}
        onBlur={() => setSearchTerm('')}
        placeholder="Type for search"
        notFoundContent={
          <>
            {isSearching ? (
              <>Loading</>
            ) : (
              <>{!debouncedSearchTerm ? <></> : <>Not Found</>}</>
            )}
          </>
        }
        onSearch={(val) => {
          setIsSearching(true);
          setSearchTerm(val);
        }}
        disabled={!disabled}
        onChange={onChangeFn}
      >
        {results.map((result) => (
          <Option value={result} key={result}>
            {result}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default DelayedAutoComplete;
