import { Card, Table } from 'antd';
import React from 'react';
import {
  browserTableColumns,
  browserTableData,
} from '../../utils/browser-tests';
import ScrollIntoView from 'react-scroll-into-view';
const HelpPage = () => {
  return (
    <Card className="margin-5r explanation-texts">
      <h2>Help</h2>
      <div>
        <h3>
          Interactive Tutorial is available by clicking the "?" button on the
          top right menu
        </h3>
      </div>
      <div>
        <hr />
        <h3>Table of Content</h3>
        <ol>
          <li>
            <ScrollIntoView
              className="content-table-item"
              selector="#introduction"
            >
              <span>Introduction</span>
            </ScrollIntoView>
            <ol>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#global-workflow"
                >
                  <span>Global Workflow of InetModels</span>
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#browser-compatibility"
                >
                  <span>Browser Compatibility</span>
                </ScrollIntoView>
              </li>
            </ol>
          </li>
          <li>
            <ScrollIntoView className="content-table-item" selector="#how-to">
              <span>How to Generate a Network from iNetModels</span>
            </ScrollIntoView>
            <ol>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#selecting-network"
                >
                  <span>Selecting the network</span>
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#querying-genes"
                >
                  <span>Querying genes/analytes of interest</span>
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#adjusting-network"
                >
                  <span>Adjusting the Network</span>
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#viewing-network"
                >
                  <span>Viewing the Network</span>
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#viewing-result-tables"
                >
                  <span>Viewing the Result Tables</span>
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#downloading-network"
                >
                  <span>Downloading the network</span>
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#using-api"
                >
                  <span>Using the API</span>
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  className="content-table-item"
                  selector="#exporting-igraph"
                >
                  <span>Exporting to iGraph and Cytoscape</span>
                </ScrollIntoView>
              </li>
            </ol>
          </li>
          <li>
            <ScrollIntoView
              className="content-table-item"
              selector="#data-availability"
            >
              <span>Data Availability</span>
            </ScrollIntoView>
          </li>
        </ol>
      </div>
      <hr />
      <div id="introduction" className="margin-top-10p">
        <h3>1. Introduction</h3>
        <p>
          iNetModels is a web-based network visualization tool that we built
          with user experience, on top of precision and accuracy, in mind. In
          this page, we are showing the step-by-step that a user can follow in
          order to maximize their experience with iNetModels.
        </p>
        <img
          src={process.env.PUBLIC_URL + '/Slide0.png'}
          alt="introduction"
          loading="lazy"
          className="about-img-item margin-2r"
        />
        <div id="global-workflow">
          <h4>1.1. Global Workflow of InetModels</h4>
          <div>
            <img
              src={process.env.PUBLIC_URL + '/Flowchart_Horizontal_final.png'}
              alt="introduction"
              loading="lazy"
              className="about-img-item margin-2r"
            />
          </div>
        </div>

        <div id="browser-compatibility">
          <h4>1.2. Browser Compatibility</h4>
          <Table
            className="margin-2r browser-table"
            bordered
            title={() => 'We have tested this platform on the following:'}
            dataSource={browserTableData}
            columns={browserTableColumns}
          />
        </div>
      </div>

      <hr />
      <div id="how-to" className="margin-top-10p">
        <h3>2. How to Generate a Network from iNetModels</h3>
        <p>
          Throughout our walkthrough below, we are using{' '}
          <a
            href="http://inetmodels.com/?networkType=Multi-Omics+Network&categoryType=SCAPIS+-+SciLifeLab+Wellness+Profiling+Study&categoryName=Longitudinal+-+Overall&analyteTypes=PROTEIN%7C%2CGUT+MICROBIOME%7C%2CCLINICAL%7C%2CMETABOLITE&analytes=L-Cysteine%7C%2CL-Serine&nodeLimit=50&pruning=0&firstNeighbour=true&visualize=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            this template example
          </a>
          .
        </p>

        <div id="selecting-network">
          <h4>2.1. Selecting the network</h4>
          <p>
            Users can select the network and specific network category as per
            their requirement via the{' '}
            <a
              href="http://inetmodels.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              search pane
            </a>
            . There are more than 100 networks to choose from, including {'>'}
            80 tissue- and cancer-specific gene co-expression networks and {'>'}
            20 multi-omics biological networks, including gender- and
            disease-specific networks.
          </p>
          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide1.png'}
              alt="selecting network"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>
        </div>

        <div id="querying-genes">
          <h4>2.2. Querying genes/analytes of interest</h4>
          <p>
            After selecting the network, the “Search” bar is enabled and users
            can input their genes/analytes of interest. Since analytes names can
            vary and hard to type, we provided auto-complete feature to show the
            closest match to the typed letters. Users may select more than one
            gene/analyte in a query, but since the network is big and it might
            cause heavy browser load during visualization, we suggest users to
            input maximum 5 analytes in a single query.
          </p>
          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide2.png'}
              alt="querying genes"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>
        </div>

        <div id="adjusting-network">
          <h4>2.3. Adjusting the Network</h4>
          <div>
            Once the analytes are selected, users can adjust the network based
            on their need. There are multiple adjustments that can be done:
            <ol>
              <li>Users can select the specific omics type (or include all)</li>
              <li>
                Users can adjust the networks based on the statistical values,
                e.g. Adjusted P-Value (to prune the edges based on their
                significance) and correlation ranking (to limit the number of
                nodes shown in the visualization)
              </li>
              <li>
                Users can select whether to show the network visualization or to
                generate only the network tables
              </li>
              <li>
                Users can select whether to include only edges to first
                neighbours of the queried analytes or to include the neighbour’s
                interactions as well{' '}
                <strong>
                  (WARNING: unchecking this option can cause lag/heavy browser
                  load in big networks)
                </strong>
                .
              </li>
            </ol>
          </div>
          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide3.png'}
              alt="adjusting network"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>
        </div>

        <div id="viewing-network">
          <h4>2.4. Viewing the Network</h4>
          <p>
            <strong>
              (This part is only relevant when “Visualize” option is selected in
              the previous step)
            </strong>
          </p>
          <p>
            Once the network is queried, almost instantly the network will
            appear in the visualization pane. Users can directly interact with
            the network visualization by moving the nodes around to adjust the
            visualization based on their needs. To signify the omics type, the
            node colours vary based on their omics type (refer to the “Legend”
            tab, below the visualization pane). Moreover, the edge colours
            signify their correlation trends (positive or negative, refer to
            “Legend” tab).
            <br />
            In case of too large network, our system will automatically hide the
            edges from the network to reduce the load. Users can enable it
            manually using the option on top right of the visualization pane.
          </p>
          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide4.png'}
              alt="viewing network"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>
        </div>

        <div id="viewing-result-tables">
          <h4>2.5. Viewing the Result Tables</h4>
          <p>
            On top of the visualization pane, users can view the edge and node
            table of the network. Edge table contains the information of the
            edges, e.g. the source, target, edge weight (correlation score), and
            adjusted P-Value.{' '}
            <strong>
              Please remember that all the networks here are undirected network.
            </strong>{' '}
            Node table includes the overall nodes information (limited), or
            based on their analyte types. In each omics-specific node table,
            more informations on the nodes are provided, which are all self
            explanatory based on the column names. We are also linking the
            information to other well-known databases when possible, such as
            KEGG, Human Protein Atlas, HMDB, and Uniprot.
          </p>
          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide5.png'}
              alt="viewing result tables"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>
        </div>

        <div id="downloading-network">
          <h4>2.6. Downloading the network</h4>
          <div>
            The network can be downloaded as several files, by clicking "Export"
            menu below the Visualization or Table pane:
            <ul>
              <li>
                Export Graph as PNG/PDF: Export the Graph Visualization pane as
                PNG/PDF (Only available if option ""Visualize"" is selected)
              </li>
              <li>
                Export Nodes/Edges as CSV: Export the Node/Edge Tables as
                comma-separated format (CSV), users have possibility to import
                all nodes or just specific analytes
              </li>
            </ul>
          </div>
          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide6.png'}
              alt="downloading network"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>
          <p>
            Users may adjust the visualization in the visualization pane and
            download the network figure to be used for publication/presentations
            directly. Moreover, if users wish to perform further network
            analysis or integration with other data, all network information in
            the visualization pane can be downloaded directly as text file and
            they are compatible with well-known network analysis tools, such as
            Cytoscape and iGraph.
          </p>
        </div>

        <div id="using-api">
          <h4>2.7. Using the API</h4>
          <p>
            iNetModels provides opportunity for users to directly integrate our
            network to their data analysis via Pyton{' '}
            <a
              href="http://inetmodels.com/inet-api"
              target="_blank"
              rel="noopener noreferrer"
            >
              API
            </a>
            . Refer to the API section to get more information.
          </p>
        </div>

        <div id="exporting-igraph">
          <h4>2.8. Exporting to iGraph and Cytoscape</h4>
          <p>
            Once the nodes and edges tables are downloaded (from webpage 2.6 or
            API 2.7), the network can be imported to iGraph or{' '}
            <a
              href="https://cytoscape.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cytoscape
            </a>
            for further analysis and style adjustments. In our{' '}
            <a
              href="https://github.com/muharif/Inetmodels/blob/main/API/API_test.ipynb"
              target="_blank"
              rel="noopener noreferrer"
            >
              API python notebook
            </a>
            , we have provided an example of how to load the network from
            iNetModels to iGraph python.
          </p>

          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide7.png'}
              alt="viewing result tables"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>

          <p>
            To import the network to Cystoscape, users must load the edges table
            (A - 1) and nodes table (A - 2). The default network style will be
            shown in the visualization pane of Cytoscape (B - 3). Next, users
            can perform basic network analysis, such as degree, betweenness,
            closeness, and clustering coefficients by using the “Analyze
            Network” option under “Tools” (B - 4). The analysis results can be
            found in the node and edges table (B - 5).
          </p>

          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide8.png'}
              alt="viewing result tables"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>

          <p>
            Furthermore, users can edit the visualization of the network using
            the “Style” option in Cystoscape to change node and edge colours,
            shape, size, font, and many other style options to get it ready for
            publications or presentations.
          </p>

          <div>
            <img
              src={process.env.PUBLIC_URL + '/Slide9.png'}
              alt="viewing result tables"
              loading="lazy"
              className="slide-img-item margin-2r"
            />
          </div>
        </div>
      </div>

      <hr />
      <div id="data-availability" className="margin-top-10p">
        <h3>3. Data Availability</h3>
        <p>
          Users can download all network data in this database, including the
          edges and nodes information via this{' '}
          <a
            href="https://inetmodels.com/files/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LINK
          </a>
          . The networks are split based on each category, so users may easily
          download just the network of interest. To find the ID mapping for the
          network, please refer to the “category_final.txt” file in the main{' '}
          <a
            href="https://inetmodels.com/files/"
            target="_blank"
            rel="noopener noreferrer"
          >
            directory
          </a>
          .<br />
          We are welcoming all questions and suggestions regarding the use of
          this service. Please contact us via email to muhammad.arif [at]
          scilifelab.se. We encourage our users and all research community to
          submit their network data to our platform via the{' '}
          <a
            href="https://inetmodels.com/upload"
            target="_blank"
            rel="noopener noreferrer"
          >
            "Upload"
          </a>{' '}
          menu (maximum 200Mb, if more, please send me an email and we’ll
          provide you with an alternative link)
        </p>
      </div>
    </Card>
  );
};

export default HelpPage;
