import { Card } from 'antd';
import React from 'react';
const ApiPage = () => {
  return (
    <Card className="margin-5r explanation-texts">
      <div>
        <h2>API Documentation</h2>
        <div>
          <p>
            For now, iNetModels support programmatic access using Python. In order to do that, please refer 
            <a
              href="https://github.com/muharif/Inetmodels/tree/main/API"
              target="_blank"
              rel="noopener noreferrer"
            >
              the GitHub Page
            </a>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default ApiPage;
