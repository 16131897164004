import React, { useRef } from 'react';
import { Spin, Tabs } from 'antd';
import { connect } from 'react-redux';
import { store } from '../../utils/types';
import DrawGraph from './DrawGraph';
import TableView from './TableView';
import Export from './Export';
import LegendBar from './LegendBar';
import Citation from './Citation';
const { TabPane } = Tabs;

type Props = {
  doQueryStatus: string;
  doQueryPayload: any;
  visualize: boolean;
};

const QueryResultArea = ({
  doQueryStatus,
  doQueryPayload,
  visualize,
}: Props) => {
  const graphWrapperRef = useRef(null);
  return (
    <>
      {doQueryStatus === 'success' ? (
        <div className="site-layout-background padding-left-20p padding-bottom-100p">
          <div className="primary-tabs">
            <Tabs defaultActiveKey="graph" onChange={() => {}}>
              {visualize && (
                <TabPane tab="Graph" key="graph">
                  <div ref={graphWrapperRef}>
                    <DrawGraph />
                  </div>
                </TabPane>
              )}
              <TabPane tab="Node Table" key="nodeTable">
                <TableView tableType="NODES" />
              </TabPane>
              <TabPane tab="Edge Table" key="linkTable">
                <TableView tableType="EDGES" />
              </TabPane>
            </Tabs>
          </div>
          <div className="secondary-tabs margin-bottom-20p">
            <Tabs defaultActiveKey="legend" onChange={() => {}}>
              <TabPane tab="Legend" key="legend">
                <LegendBar />
              </TabPane>
              <TabPane tab="Export" key="export">
                <Export graphWrapperRef={graphWrapperRef} />
              </TabPane>
              <TabPane tab="Citation" key="citation">
                <Citation />
              </TabPane>
            </Tabs>
          </div>
        </div>
      ) : doQueryStatus === 'loading' ? (
        <div className="center-spinner">
          <Spin size="large" />
        </div>
      ) : (
        doQueryStatus === 'fail' && (
          <div className="justify-center">
            <p>Error occured: {doQueryPayload}</p>
          </div>
        )
      )}
    </>
  );
};

const mapStateToProps = (state: store) => ({
  doQueryStatus: state.query.doQueryOp.status,
  doQueryPayload: state.query.doQueryOp.payload,
  visualize: state.query.requestedQueryDetails?.visualize,
});

export default connect(mapStateToProps)(QueryResultArea);
