import { Dispatch, QueryRequest, QueryResponse, store } from '../utils/types';
import { query as queryService } from '../services/query';
import { resetModals } from './graph-modals';
import { includeNodesToLinks } from '../utils/helpers';
import * as H from 'history';

export const doQueryOperation = (
  query: QueryRequest,
  status: string,
  payload?: any,
  queryResponse?: QueryResponse
) => ({
  query,
  type: 'query/DO_QUERY',
  status,
  payload,
  queryResponse,
});

export const makeQuery = (queryRequest: QueryRequest) => {
  return (dispatch: Dispatch) => {
    dispatch(resetModals());
    dispatch(doQueryOperation(queryRequest, 'loading', undefined));
    queryService(queryRequest).then(
      (res) => {
        if (!queryRequest.visualize) {
          includeNodesToLinks(res.data);
        }
        dispatch(
          doQueryOperation(queryRequest, 'success', undefined, res.data)
        );
      },
      (err) => {
        dispatch(doQueryOperation(queryRequest, 'fail', '' + err));
      }
    );
    window.gtag('event', 'query', {
      event_category: 'query-request',
      full_query: JSON.stringify(queryRequest),
      ...queryRequest,
    });
  };
};

export const updateCurrentQueryDetiails = (
  currentQueryDetails: QueryRequest
) => ({
  type: 'query/UPDATE_CURRENT_QUERY_DETAILS',
  currentQueryDetails,
});

export const patchCurrentQueryDetiails = (updatedFields: any) => {
  return (dispatch: Dispatch, getState: any) => {
    const currQueryDetails = (getState() as store).query.currentQueryDetails;
    dispatch(
      updateCurrentQueryDetiails({ ...currQueryDetails, ...updatedFields })
    );
  };
};

export const setShowTutorial = (showTutorial: boolean) => ({
  type: 'query/SET_SHOW_TUTORIAL',
  showTutorial,
});

export const queryWithCurrentQueryDetails = (history: H.History<unknown>) => {
  return (dispatch: Dispatch, getState: any) => {
    const currQueryDetails = (getState() as store).query.currentQueryDetails;
    // @ts-ignore
    delete currQueryDetails.clustering; // TODO will be deleted when clustering active
    const queryObj: any = {
      ...currQueryDetails,
      analyteTypes: currQueryDetails.analyteTypes.join('|,'),
      analytes: currQueryDetails.analytes.join('|,'),
    };
    const queryParams = new URLSearchParams(queryObj).toString();
    history.push('/?' + queryParams);
  };
};

export const resetQueryResponse = () => ({
  type: 'query/RESET_QUERY_RESPONSE',
});
