export const browserTableColumns = [
  {
    title: 'OS',
    dataIndex: 'os',
    key: 'os',
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
  },
  {
    title: 'Chrome',
    dataIndex: 'chrome',
    key: 'chrome',
  },
  {
    title: 'Firefox',
    dataIndex: 'firefox',
    key: 'firefox',
  },
  {
    title: 'Microsoft Edge',
    dataIndex: 'edge',
    key: 'edge',
  },
  {
    title: 'Opera',
    dataIndex: 'opera',
    key: 'opera',
  },
  {
    title: 'Safari',
    dataIndex: 'safari',
    key: 'safari',
  },
];

export const browserTableData = [
  {
    key: 1,
    os: 'MacOS',
    version: 'Catalina (10.15.7)',
    chrome: '87.0.4280.67',
    firefox: '82.0.3',
    edge: 'Not Tested',
    opera: 'Not Tested',
    safari: '14.0',
  },
  {
    key: 2,
    os: 'Linux',
    version: 'Centos (7.8.2003)',
    chrome: 'Not Tested',
    firefox: '68.12.0esr',
    edge: 'Not Tested',
    opera: 'Not Tested',
    safari: '-',
  },
  {
    key: 3,
    os: 'Microsoft Windows',
    version: 'Windows 10',
    chrome: '87.0.4280.67',
    firefox: 'Not Tested',
    edge: 'Not Tested',
    opera: 'Not Tested',
    safari: '-',
  },
];
