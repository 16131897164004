import { QueryRequest, QueryResponse } from '../utils/types';

const queryDefaultState = {
  queryResponse: undefined,
  doQueryOp: { status: 'init', payload: '' },
  requestedQueryDetails: undefined,
  currentQueryDetails: {
    firstNeighbour: true,
    visualize: true,
    networkType: undefined,
    categoryType: undefined,
    categoryName: undefined,
    // clustering: true,
    pruning: 0,
    analyteTypes: [],
    nodeLimit: 25,
    analytes: [],
    correlation: 'both',
  },
  moveQueryAreaLeft: false,
  showTutorial: false,
};

export default (
  state = queryDefaultState,
  action: {
    type: string;
    status?: string;
    payload?: any;
    currentQueryDetails: QueryRequest;
    query: QueryRequest;
    showTutorial: boolean;
    queryResponse?: QueryResponse;
  }
) => {
  switch (action.type) {
    case 'query/DO_QUERY':
      return {
        ...state,
        queryResponse: action.queryResponse,
        doQueryOp: {
          status: action.status,
          payload: action.payload,
        },
        requestedQueryDetails: action.query,
        moveQueryAreaLeft: true,
      };
    case 'query/UPDATE_CURRENT_QUERY_DETAILS':
      return {
        ...state,
        currentQueryDetails: action.currentQueryDetails,
      };
    case 'query/SET_SHOW_TUTORIAL':
      return {
        ...state,
        showTutorial: action.showTutorial,
      };
    case 'query/RESET_QUERY_RESPONSE':
      return {
        ...state,
        queryResponse: { nodes: [], links: [] },
        doQueryOp: { status: 'init', payload: '' },
        requestedQueryDetails: undefined,
        moveQueryAreaLeft: false,
      };
    default:
      return state;
  }
};
