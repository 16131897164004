import React, { MutableRefObject, useState } from 'react';
// @ts-ignore
import { useJsonToCsv } from 'react-json-csv';
import { saveAs } from 'file-saver';
import { Button, Popover } from 'antd';
import { QueryResponse, store, LinkWithNodes } from '../../utils/types';
import jsPDF from 'jspdf';
import { connect } from 'react-redux';
import { fieldExplanationMap } from '../../utils/helpers';

type Props = {
  queryResponse: QueryResponse;
  graphWrapperRef: MutableRefObject<any | undefined>;
  visualize: boolean;
};
const Export = ({ queryResponse, graphWrapperRef, visualize }: Props) => {
  const [loadings, setLoadings] = useState<any>({});
  const { saveAsCsv } = useJsonToCsv();
  const handleExportGraphPng = () => {
    setLoadings((loads: any) => ({ ...loads, graphPng: true }));
    if (graphWrapperRef.current) {
      const canvas = graphWrapperRef.current.querySelector('canvas');
      if (canvas) {
        canvas.toBlob(function (blob: any) {
          saveAs(blob, 'export-graph.png');
        });
      }
    }
    setLoadings((loads: any) => ({ ...loads, graphPng: false }));
  };

  const handleExportGraphPdf = () => {
    setLoadings((loads: any) => ({ ...loads, graphPdf: true }));
    setTimeout(() => {
      if (graphWrapperRef.current) {
        const canvas = graphWrapperRef.current.querySelector('canvas');
        if (canvas) {
          const canvasPng = canvas.toDataURL('img/png');

          const doc = new jsPDF({ orientation: 'landscape', format: 'a2' });
          doc.addImage(
            canvasPng,
            'png',
            10,
            10,
            doc.internal.pageSize.getWidth() - 20,
            doc.internal.pageSize.getHeight() - 20
          );
          doc.save('export-graph.pdf');
        }
      }

      setLoadings((loads: any) => ({ ...loads, graphPdf: false }));
    }, 10);
  };

  const handleExportNodesCsv = (type: string) => {
    setLoadings((loads: any) => ({ ...loads, nodesCsv: true }));
    const data =
      type === 'ALL'
        ? queryResponse.nodes
        : queryResponse.nodes.filter((node) => node.location === type);
    saveAsCsv({
      data,
      fields: fieldExplanationMap[type],
      fileformat: 'txt',
      filename: 'nodes_' + type,
      separator: '\t',
    });
    setLoadings((loads: any) => ({ ...loads, nodesCsv: false }));
  };

  const handleExportEdgesCsv = () => {
    setLoadings((loads: any) => ({ ...loads, edgesCsv: true }));
    setTimeout(() => {
      const data = (queryResponse.links as LinkWithNodes[]).map(
        (link: LinkWithNodes) => ({
          source: link.source.symbol,
          target: link.target.symbol,
          score: link.correlation,
          pvalue: link.pvalue,
          padj: link.padj,
        })
      );
      saveAsCsv({
        data,
        fields: {
          source: 'Source',
          target: 'Target',
          score: 'Score',
          pvalue: 'PVal',
          padj: 'Padj',
        },
        fileformat: 'txt',
        filename: 'edges',
        separator: '\t',
      });
      setLoadings((loads: any) => ({ ...loads, edgesCsv: false }));
    }, 10);
  };

  const content = (
    <div className="flex-column">
      {Object.keys(fieldExplanationMap).map((type) => (
        <Button
          key={type}
          className="margin-top-10p"
          onClick={() => handleExportNodesCsv(type)}
        >
          Export {type} nodes
        </Button>
      ))}
    </div>
  );

  return (
    <>
      <div className="export-buttons">
        {visualize && (
          <>
            <Button loading={loadings.graphPng} onClick={handleExportGraphPng}>
              Export Graph as PNG
            </Button>
            <Button loading={loadings.graphPdf} onClick={handleExportGraphPdf}>
              Export Graph as PDF
            </Button>
          </>
        )}

        <Popover
          placement="topRight"
          title={<span>Node CSV Export</span>}
          content={content}
          trigger="click"
        >
          <Button loading={loadings.nodesCsv}>Export Nodes</Button>
        </Popover>
        <Button loading={loadings.edgesCsv} onClick={handleExportEdgesCsv}>
          Export Edges
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: store) => ({
  queryResponse: state.query.queryResponse,
  visualize: state.query.requestedQueryDetails?.visualize,
});
export default connect(mapStateToProps)(Export);
