import { ReactourStep } from 'reactour';
import { func } from './types';

export const getTourSteps = (
  patchCurrentQueryDetailsAction: func,
  queryWithCurrentQueryDetailsAction: func,
  history: any,
  setGoToStep: func
) => {
  return [
    {
      selector: '#network-select',
      content:
        'Welcome to iNetModels! In order to make query, select the desired network type (Gene Co-Expression Networks/GCNs or Multi-Omics Biological Network/MOBNs)',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            networkType: 'Multi-Omics Network',
          });
        }, 200);
      },
    },
    {
      selector: '#category-type-select',
      content:
        'Next, select tissue- or cancer-specific for GCN or study-specific for MOBN',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            categoryType: 'SCAPIS - SciLifeLab Wellness Profiling Study',
          });
        }, 200);
      },
    },
    {
      selector: '#category-select',
      content:
        'Then, select the specific desired network (i.e. liver cancer, female overall network)',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            categoryName: 'Longitudinal - Overall',
          });
        }, 200);
      },
    },
    {
      selector: '#search-tags',
      content:
        'Type genes/analyte names and select from the drop-down window (suggestion: not more than 5 genes/analytes)',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            analytes: ['L-Cysteine', 'L-Serine'],
          });
        }, 200);
      },
    },
    {
      selector: '#type-of-analytes',
      content: 'Select the available omics types to be included in the network',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            analyteTypes: [
              'METABOLITE',
              'CLINICAL',
              'PROTEIN',
              'GUT MICROBIOME',
            ],
          });
        }, 200);
      },
    },
    {
      selector: '#edge-pruning',
      content:
        'Adjust the minimum significance level of the edges (based on Adjusted P-Value/FDR)',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            pruning: 0,
          });
        }, 200);
      },
    },
    {
      selector: '#node-limit',
      content:
        'Adjust the number of nodes to be represented in the network (based on correlation ranking)',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            nodeLimit: 50,
          });
        }, 200);
      },
    },
    {
      selector: '#correlation',
      content: 'Select the correlation type.',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            correlation: 'both',
          });
        }, 200);
      },
    },
    {
      selector: '#additional-options',
      content:
        'Select whether to visualize the network (or just to generate network tables) and whether to include only edges to first neighbours (or show interaction among first neighbours, may cause heavy browser load)',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          patchCurrentQueryDetailsAction({
            firstNeighbour: true,
            visualize: true,
          });
        }, 200);
      },
    },
    {
      selector: '#query-search-button',
      content: 'Now clicking on search to start the query',
      stepInteraction: false,
      position: 'right',
      action: () => {
        setTimeout(() => {
          queryWithCurrentQueryDetailsAction(history);
        }, 200);

        setTimeout(() => {
          setGoToStep(10);
        }, 900);
      },
    },
    {
      selector: '.content-area-wrapper',
      content: `This is the visualization pane. You can view and interact with the networks.
      Network tables can be found above the network visualization, whereas the legend, export/download, and citation tabs can be found under.`,
      stepInteraction: false,
      position: 'top',
    },
    {
      selector: '.primary-tabs .ant-tabs-tab:nth-child(1)',
      content: 'Network visualization is available under the “Graph” tab',
      stepInteraction: false,
      position: 'left',
    },
    {
      selector: '.graph-wrapper',
      content:
        'Click on the nodes and edges to get more information about it. You can also zoom in and out the graphs and move the nodes around',
      stepInteraction: false,
      position: 'left',
    },
    {
      selector: '.primary-tabs .ant-tabs-tab:nth-child(2)',
      content:
        'Node table includes the overall nodes information (limited), or based on their analyte types with detailed informations.',
      stepInteraction: false,
      position: 'right',
    },
    {
      selector: '.primary-tabs .ant-tabs-tab:nth-child(3)',
      content:
        'Edge table contains the information of the edges, e.g. the source, target, edge weight (correlation score), and adjusted P-Value.',
      stepInteraction: false,
      position: 'left',
    },
    {
      selector: '.secondary-tabs .ant-tabs-tab:nth-child(1)',
      content:
        'The nodes are coloured differently based on their omics type and edges are coloured based on their correlation trend. You can find the mapping under the “Legend” tab',
      stepInteraction: false,
      position: 'left',
    },
    {
      selector: '.secondary-tabs .ant-tabs-tab:nth-child(2)',
      content:
        'Networks can be downloaded as graph/picture (PNG/PDF) or as network tables (text, CSV) for further network analysis in Cytoscape, iGraph, or other network tools',
      stepInteraction: false,
      position: 'left',
    },
    {
      selector: '.secondary-tabs .ant-tabs-tab:nth-child(3)',
      content:
        '“Citation” tab includes information about how to cite iNetModels in your research/publications',
      stepInteraction: false,
      position: 'left',
    },
  ] as ReactourStep[];
};
