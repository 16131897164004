import { Layout, Spin } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setInitData } from '../../actions/data-types';
import { resetQueryResponse } from '../../actions/query';
import { Dispatch, func, store } from '../../utils/types';
import QueryResultArea from '../common-components/QueryResultArea';
import TourComponent from '../common-components/TourComponent';
import Sidebar from '../layouts/Sidebar';

const { Content } = Layout;

type Props = {
  moveQueryAreaLeft?: boolean;
  firstInitDataTypes: string;
  setInitDataAction(): func;
  resetQueryResponseAction(): func;
};

const QueryPage = ({
  moveQueryAreaLeft,
  firstInitDataTypes,
  setInitDataAction,
  resetQueryResponseAction,
}: Props) => {
  useEffect(() => {
    setInitDataAction();
    return () => {
      resetQueryResponseAction();
    };
  }, [setInitDataAction, resetQueryResponseAction]);
  const sidebarClass = !moveQueryAreaLeft
    ? 'sider-wrapper'
    : 'sider-wrapper-shorten';
  return (
    <>
      <TourComponent />
      {firstInitDataTypes === 'success' ? (
        <>
          <div className={sidebarClass}>
            <Sidebar />
          </div>
          {moveQueryAreaLeft && (
            <Content className="content-area-wrapper">
              <QueryResultArea />
            </Content>
          )}
        </>
      ) : firstInitDataTypes === 'init' ? (
        <Spin size="large" className="margin-top-50p" />
      ) : (
        firstInitDataTypes === 'fail' && (
          <div className="justify-center margin-top-20p">
            <p>Upss! Someting went wrong...</p>
          </div>
        )
      )}
    </>
  );
};

const mapStateToProps = (state: store) => ({
  moveQueryAreaLeft: state.query.moveQueryAreaLeft,
  firstInitDataTypes: state.dataTypes.firstInitData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setInitDataAction: () => dispatch(setInitData()),
  resetQueryResponseAction: () => dispatch(resetQueryResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryPage);
