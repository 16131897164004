import axios from 'axios';
import { apiBaseUrl } from '../config/constants';

export const getUploadLink = (data: any) => {
  return axios.post(apiBaseUrl + '/get-upload-link', data);
};

export const uploadZipFile = (
  signedUrl: string,
  file: any,
  fileFormat: any
) => {
  return axios.put(signedUrl, file, {
    headers: { 'Content-Type': fileFormat },
  });
};
