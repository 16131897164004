import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Input, Row, Upload } from 'antd';
import React, { useCallback, useState } from 'react';
import { getUploadLink, uploadZipFile } from '../../services/upload';
const { TextArea } = Input;

const fields = [
  { field: 'networkName', label: 'Network', type: 'input' },
  { field: 'categoryType', label: 'Category Type', type: 'input' },
  { field: 'categoryName', label: 'Category Name', type: 'input' },
  { field: 'description', label: 'Description', type: 'textarea' },
  { field: 'citation', label: 'Citation / Link to Data', type: 'input' },
  { field: 'name', label: 'Name', type: 'input' },
  { field: 'affiliation', label: 'Affiliation', type: 'input' },
  { field: 'email', label: 'Email', type: 'input' },
];

const UploadPage = () => {
  const [inputFields, setInputFields] = useState<any>({});
  const [fileList, setFileList] = useState<any>([]);
  const [uploading, setUploading] = useState({ status: 'init', payload: '' }); // init, loading, success, error

  const validateEmail = useCallback((email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }, []);

  const showError = useCallback(
    (message: string) => {
      setUploading({
        status: 'error',
        payload: message,
      });
    },
    [setUploading]
  );

  const checkFields = useCallback(
    (inputFields: any) => {
      for (const f of fields) {
        // @ts-ignore
        if (!inputFields[f.field]) {
          return false;
        }
      }

      if (!validateEmail(inputFields.email)) {
        throw new Error('The given email format is wrong!');
      }
      return true;
    },
    [validateEmail]
  );

  const handleSend = async () => {
    setUploading({ status: 'loading', payload: '' });
    try {
      const file = fileList[0];
      if (
        file &&
        file.name &&
        file.name.split('.')[1] === 'zip' &&
        checkFields(inputFields)
      ) {
        const data = {
          ...inputFields,
          fileName: file.name,
          fileSizeBytes: file.size,
          fileFormat: file.type,
        };
        const urlResponse = await getUploadLink(data);
        if (urlResponse.data?.signedUrl) {
          const uploadResponse = await uploadZipFile(
            urlResponse.data.signedUrl,
            file,
            file.type
          );
          if (uploadResponse.status === 200) {
            setUploading({
              status: 'success',
              payload: 'File uploaded successfully.',
            });
          } else {
            showError('Something went wrong while updating data.');
          }
        }
      } else {
        if (!file || !file.name || file.name.split('.')[1] !== 'zip') {
          showError('Please select a file with correct type. (.zip)');
        } else {
          showError('Please fill all required fields.');
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        showError(
          err.response.data.message +
            ' - ' +
            err.response.data.details?.join(',')
        );
      } else {
        showError(err.message);
      }
    }
  };
  return (
    <Card className="margin-5r explanation-texts">
      <div>
        <h2 className="text-center">Upload Data</h2>
        <Row className="justify-center">
          <Col md={24} lg={12}>
            <p>
              You can submit your network data to iNetModels on this page. After
              uploading, we will review your network in maximum of 3 working
              days and once it is approved, we’ll send you confirmation.
            </p>
            <p>
              Please follow the template from
              <a
                href="https://github.com/muharif/Inetmodels/tree/main/Template"
                target="_blank"
                rel="noopener noreferrer"
              >
                this GitHub link
              </a>
            </p>
          </Col>
          <Col xs={24}></Col>
          <Col md={24} lg={12}>
            {fields.map((field, i) => (
              <Row key={field.field} className="align-center margin-top-10p">
                {i === 4 && (
                  <Col xs={24}>
                    <h3 className="margin-top-20p">Contact Information</h3>
                  </Col>
                )}
                <Col xs={13} md={8} lg={6}>
                  {field.label} *
                </Col>
                <Col className="margin-left-1r" xs={16} md={14} lg={12}>
                  {field.type === 'input' ? (
                    <Input
                      onChange={(val) => {
                        const { currentTarget } = val;
                        setInputFields((inputs: any) => ({
                          ...inputs,
                          [field.field]: currentTarget.value,
                        }));
                      }}
                    />
                  ) : (
                    field.type === 'textarea' && (
                      <TextArea
                        rows={4}
                        onChange={(val) => {
                          const { currentTarget } = val;
                          setInputFields((inputs: any) => ({
                            ...inputs,
                            [field.field]: currentTarget.value,
                          }));
                        }}
                      />
                    )
                  )}
                </Col>
              </Row>
            ))}

            <Row className="align-center margin-top-10p">
              <Col xs={13} md={8} lg={6}>
                Upload (.zip file) *
              </Col>
              <Col className="margin-left-1r" xs={16} md={14} lg={12}>
                <Upload
                  fileList={fileList}
                  accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                  onRemove={(file: any) => {
                    setFileList((fileList: any) => {
                      return fileList.filter((f: any) => f !== file);
                    });
                  }}
                  beforeUpload={(file: any) => {
                    setFileList([file]);
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Col>
            </Row>

            <Row className="align-center margin-top-10p">
              <Col xs={13} md={8} lg={6}></Col>
              <Col className="margin-left-1r" xs={16} md={14} lg={12}>
                * The maximum allowed file size is <b>250MB</b>. If your data is
                greater than 250MB, please contact with{' '}
                <i>muhammad.arif [at] scilifelab.se</i>
              </Col>
            </Row>

            <Row className="align-center margin-top-20p">
              <Col xs={13} md={8} lg={6}></Col>
              <Col
                className="margin-left-1r justify-end"
                xs={16}
                md={14}
                lg={12}
              >
                <Button
                  type="primary"
                  onClick={handleSend}
                  loading={uploading.status === 'loading'}
                >
                  Send
                </Button>
              </Col>
            </Row>

            {(uploading.status === 'success' ||
              uploading.status === 'error') && (
              <Alert
                className="align-center margin-top-10p"
                message={uploading.status === 'success' ? 'Success' : 'Error'}
                description={uploading.payload}
                type={uploading.status}
              />
            )}
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default UploadPage;
