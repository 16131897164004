import { LinkWithNodes, Node } from '../utils/types';

export const removeNode = (nodeId: number | string) => ({
  type: 'graph-modals/REMOVE_NODE',
  nodeId,
});

export const removeLink = (
  sourceId: number | string,
  targetId: number | string
) => ({
  type: 'graph-modals/REMOVE_LINK',
  sourceId,
  targetId,
});

export const addNode = (node: Node) => ({
  type: 'graph-modals/ADD_NODE',
  node,
});

export const addLink = (link: LinkWithNodes) => ({
  type: 'graph-modals/ADD_LINK',
  link,
});

export const resetModals = () => ({
  type: 'graph-modals/RESET_MODALS',
});
