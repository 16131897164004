import axios from 'axios';
import { apiBaseUrl } from '../config/constants';

export const suggestAutoComplete = (
  keyword: string,
  extraArgs: { networkType: string; categoryType: string; categoryName: string }
) => {
  const { networkType, categoryType, categoryName } = extraArgs;
  return axios.post<string[]>(apiBaseUrl + '/auto-complete', {
    networkType,
    categoryType,
    categoryName,
    keyword,
  });
};
