import { Col, InputNumber, Row, Slider } from 'antd';
import React from 'react';

type Props = {
  min: number;
  max: number;
  step: number;
  value?: number;
  onChangeFn: any;
};
const InputNumberWithSlider = ({
  min,
  max,
  step,
  value,
  onChangeFn,
}: Props) => {
  return (
    <Row>
      <Col span={16}>
        <Slider
          min={min}
          max={max}
          onChange={onChangeFn}
          value={typeof value === 'number' ? value : 0}
          step={step}
        />
      </Col>
      <Col span={6}>
        <InputNumber
          min={min}
          max={max}
          className="slider-input-margin"
          value={value}
          step={step}
          onChange={onChangeFn}
        />
      </Col>
    </Row>
  );
};

export default InputNumberWithSlider;
