import React, { useEffect } from 'react';
import { Row, Card, Checkbox, Button, Select } from 'antd';
import { DoubleLeftOutlined } from '@ant-design/icons';
import {
  CategoryDataTypeMap,
  Dispatch,
  FixType,
  func,
  QueryRequest,
  store,
} from '../../utils/types';
import { connect } from 'react-redux';
import InputNumberWithSlider from './InputNumberWithSlider';
import DelayedAutoComplete from './DelayedAutoComplete';
import { suggestAutoComplete } from '../../services/auto-complete';
import {
  makeQuery,
  queryWithCurrentQueryDetails,
  updateCurrentQueryDetiails,
} from '../../actions/query';
import { useHistory, useLocation } from 'react-router-dom';
const { Option } = Select;

type Props = {
  moveQueryAreaLeft: boolean;
  allDataTypes?: string[];
  makeQueryAction: func;
  categoryDataTypeMap?: CategoryDataTypeMap;
  toggleSider(): void;
  values: any;
  setValues: func;
  queryWithCurrentQueryDetailsAction: func;
};
const QueryArea = ({
  moveQueryAreaLeft,
  makeQueryAction,
  allDataTypes,
  categoryDataTypeMap,
  toggleSider,
  values,
  setValues,
  queryWithCurrentQueryDetailsAction,
}: Props) => {
  const queryStrings = useLocation();
  const history = useHistory();

  const checkQueryValidity = () => {
    return (
      !values.categoryName ||
      values.analyteTypes.length === 0 ||
      values.analytes.length === 0
    );
  };

  const handleSearch = () => {
    queryWithCurrentQueryDetailsAction(history);
  };

  useEffect(() => {
    const params = new URLSearchParams(queryStrings.search);

    const values: FixType = {
      networkType: params.get('networkType'),
      categoryType: params.get('categoryType'),
      categoryName: params.get('categoryName'),
      analyteTypes: params.get('analyteTypes')?.split('|,'),
      analytes: params.get('analytes')?.split('|,'),
      nodeLimit: Number(params.get('nodeLimit')),
      pruning: Number(params.get('pruning')),
      firstNeighbour: params.get('firstNeighbour') === 'true',
      clustering: true, // TODO params.get('clustering') === 'true'
      visualize: params.get('visualize') === 'true',
      correlation: params.get('correlation') || 'both',
    };

    if (values.categoryName && values.analytes) {
      makeQueryAction(values);
      setValues(values);
    }
  }, [queryStrings, makeQueryAction, setValues]);

  const isTypeAvailableForType = (type: string) => {
    return (
      values.categoryName &&
      categoryDataTypeMap &&
      categoryDataTypeMap[values.networkType] &&
      categoryDataTypeMap[values.networkType][values.categoryType] &&
      categoryDataTypeMap[values.networkType][values.categoryType][
        values.categoryName
      ]?.some((dType: string) => dType === type)
    );
  };

  return (
    <>
      <div className="justify-space-btw align-baseline padding-left-20p padding-right-10p">
        <h3>Search</h3>
        {moveQueryAreaLeft && (
          <span onClick={toggleSider}>
            <DoubleLeftOutlined />
          </span>
        )}
      </div>
      <Row className="row-direction-column padding-right-20p padding-left-20p padding-bottom-2r">
        <div id="network-select" className="margin-top-10p">
          <div>Network</div>
          <Select
            showSearch
            size="large"
            className="w-100 query-select"
            placeholder="Please select"
            optionFilterProp="children"
            value={values.networkType}
            onChange={(val) => {
              setValues({
                ...values,
                analyteTypes: [],
                networkType: val,
                categoryType: undefined,
                categoryName: undefined,
                analytes: [],
              });
            }}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {Object.keys(categoryDataTypeMap as any)
              ?.sort()
              .map((mainCat) => (
                <Option key={mainCat} value={mainCat}>
                  {mainCat}
                </Option>
              ))}
          </Select>
        </div>

        <div id="category-type-select" className="margin-top-10p">
          <div>Category type</div>
          <Select
            showSearch
            size="large"
            className="w-100 query-select"
            disabled={!values.networkType}
            placeholder="Please select"
            optionFilterProp="children"
            value={values.categoryType}
            onChange={(val) => {
              setValues({
                ...values,
                analyteTypes: [],
                categoryType: val,
                categoryName: undefined,
                analytes: [],
              });
            }}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categoryDataTypeMap &&
              categoryDataTypeMap[values.networkType] &&
              Object.keys(
                // @ts-ignore
                (categoryDataTypeMap as CategoryDataTypeMap)[values.networkType]
              )
                .filter((k) => k !== 'NAFLD CMCS Treatment' && k !== 'Exercise Muscle' && k !== 'Exercise Muscle Proteomics' && k !== 'Study-specific Networks')
                .sort()
                .map((parentCat) => (
                  <Option key={parentCat} value={parentCat}>
                    {parentCat}
                  </Option>
                ))}
          </Select>
        </div>

        <div id="category-select" className="margin-top-10p">
          <div>Category</div>
          <Select
            size="large"
            className="w-100 query-select"
            showSearch
            disabled={!values.networkType || !values.categoryType}
            placeholder="Please select"
            optionFilterProp="children"
            value={values.categoryName}
            onChange={(val) => {
              const dTypes = (categoryDataTypeMap as CategoryDataTypeMap)[
                values.networkType
              ][values.categoryType][val];

              setValues({
                ...values,
                analyteTypes: dTypes ? dTypes : [],
                categoryName: val,
                analytes: [],
              });
            }}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categoryDataTypeMap &&
              categoryDataTypeMap[values.networkType] &&
              categoryDataTypeMap[values.networkType][values.categoryType] &&
              Object.keys(
                // @ts-ignore
                (categoryDataTypeMap as CategoryDataTypeMap)[
                  values.networkType
                ][values.categoryType]
              )
                .sort()
                .map((category) => (
                  <Option key={category} value={category}>
                    {category}
                  </Option>
                ))}
          </Select>
        </div>

        <div id="search-tags" className="margin-top-10p">
          <span> Search</span>
          <DelayedAutoComplete
            disabled={!!values.categoryName}
            value={values.analytes}
            delay={200}
            onChangeFn={(val: string[]) => {
              setValues({ ...values, analytes: val });
            }}
            fetchMethod={suggestAutoComplete}
            fetchMethodExtraArgs={{
              networkType: values.networkType,
              categoryType: values.categoryType,
              categoryName: values.categoryName,
            }}
          />
        </div>
        <div id="type-of-analytes" className="margin-top-10p data-type-options">
          <span>Type of analytes</span>
          <Card>
            {allDataTypes?.map((dataType) => (
              <p key={dataType}>
                <Checkbox
                  checked={values.analyteTypes.includes(dataType)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      values.analyteTypes.push(dataType);
                    } else {
                      values.analyteTypes = values.analyteTypes.filter(
                        (type: string) => type !== dataType
                      );
                    }
                    setValues({ ...values });
                  }}
                  disabled={!isTypeAvailableForType(dataType)}
                >
                  {dataType}
                </Checkbox>
              </p>
            ))}
          </Card>
        </div>
        <div id="edge-pruning" className="margin-top-10p">
          <span>Edge pruning parameter: -Log10 (Adjusted P-Value)</span>
          <div>
            <InputNumberWithSlider
              min={0}
              max={50}
              step={0.1}
              value={values.pruning}
              onChangeFn={(val: any) => {
                setValues({
                  ...values,
                  pruning: val,
                });
              }}
            />
          </div>
        </div>
        <div id="node-limit" className="margin-top-10p">
          <span>Node limit</span>
          <div>
            <InputNumberWithSlider
              min={1}
              max={50}
              step={1}
              value={values.nodeLimit}
              onChangeFn={(val: any) => {
                setValues({
                  ...values,
                  nodeLimit: val,
                });
              }}
            />
          </div>
        </div>
        <div id="correlation">
          <p className="zero-margin-bottom margin-top-10p">Correlation</p>
          <Select
            className="w-100 query-select"
            value={values.correlation}
            onChange={(val) => {
              setValues({
                ...values,
                correlation: val,
              });
            }}
          >
            <Option value="both">Both</Option>
            <Option value="positive">Positive</Option>
            <Option value="negative">Negative</Option>
          </Select>
        </div>
        <div
          id="additional-options"
          className="margin-top-10p data-type-options"
        >
          <span>Additional options</span>
          <Card className="padding-bottom-2r">
            <p>
              <Checkbox
                checked={!values.visualize}
                onChange={(val) => {
                  setValues({
                    ...values,
                    visualize: !val.target.checked,
                  });
                }}
              >
                No visualization
              </Checkbox>
            </p>
            <p>
              <Checkbox
                checked={!values.firstNeighbour}
                onChange={(val) => {
                  setValues({
                    ...values,
                    firstNeighbour: !val.target.checked,
                  });
                }}
              >
                Include neighbour connections
              </Checkbox>
            </p>
            {/* <p>
              <Checkbox
                checked={values.clustering}
                onChange={(val) => {
                  setValues({
                    ...values,
                    clustering: val.target.checked,
                  });
                }}
              >
                Clustering
              </Checkbox>
            </p> */}
          </Card>
        </div>

        <div id="query-search-button" className="margin-top-20p">
          <Button onClick={handleSearch} disabled={checkQueryValidity()}>
            Search
          </Button>
        </div>
      </Row>
    </>
  );
};

const mapStateToProps = (state: store) => ({
  moveQueryAreaLeft: state.query.moveQueryAreaLeft,
  allDataTypes: state.dataTypes.allDataTypes,
  categoryDataTypeMap: state.dataTypes.catDataTypeMaps,
  values: state.query.currentQueryDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  makeQueryAction: (queryRequest: QueryRequest) =>
    dispatch(makeQuery(queryRequest)),
  setValues: (currentQueryDetails: QueryRequest) =>
    dispatch(updateCurrentQueryDetiails(currentQueryDetails)),
  queryWithCurrentQueryDetailsAction: (history: any) =>
    dispatch(queryWithCurrentQueryDetails(history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryArea);
