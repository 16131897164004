import { getInitialData } from '../services/data-types';
import { DataTypes, Dispatch } from '../utils/types';

export const setDataTypes = (data: DataTypes) => {
  return {
    type: 'data-types/SET_DATA_TYPES',
    allDataTypes: data.dataTypes,
    categoryDataTypeMap: data.categoryDataTypeMap,
  };
};

export const setInitData = () => {
  return (dispatch: Dispatch) => {
    getInitialData()
      .then((res) => {
        dispatch(setDataTypes(res.data));
      })
      .catch((err: any) => {
        dispatch({ type: 'data-types/SET_FAIL' });
      });
  };
};
