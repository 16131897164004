import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { baseUrl } from '../config/constants';
import AboutPage from './pages/AboutPage';
import ApiPage from './pages/ApiPage';
import HelpPage from './pages/HelpPage';
import QueryPage from './pages/QueryPage';
import UploadPage from './pages/UploadPage';

const AppRouter = () => {
  return (
    <Switch>
      <Route path={baseUrl + '/'} component={QueryPage} exact />
      <Route path={baseUrl + '/help'} component={HelpPage} exact />
      <Route path={baseUrl + '/about'} component={AboutPage} exact />
      <Route path={baseUrl + '/inet-api'} component={ApiPage} exact />
      <Route path={baseUrl + '/upload'} component={UploadPage} exact />
      <Redirect to={baseUrl} />
    </Switch>
  );
};

export default AppRouter;
