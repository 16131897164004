import React from 'react';
import { Col, Row } from 'antd';
import {
  colorSchema,
  fieldExplanationMap,
  linkColors,
} from '../../utils/helpers';

const LegendBar = () => {
  return (
    <Row>
      <Col span={8}>
        <Row>
          <b>NODES</b>
        </Row>
        <Row className="wrap">
          {Object.keys(fieldExplanationMap)
            .filter((k) => k !== 'ALL')
            .map((nodeType) => (
              <div key={nodeType} className="w-12rem padding-right-20p">
                <div
                  className="legend-node"
                  style={{ backgroundColor: colorSchema(nodeType) }}
                ></div>
                <span>{nodeType}</span>
              </div>
            ))}
        </Row>
      </Col>
      <Col span={2}>
        <div className="legend-divider"></div>
      </Col>
      <Col span={8}>
        <Row>
          <b>EDGES</b>
        </Row>
        <Row>
          <Col span={16}>
            {Object.keys(linkColors)
              .filter((k) => k !== 'ZERO CORRELATION')
              .map((linkType) => (
                <div key={linkType}>
                  <div
                    className="legend-link"
                    style={{ backgroundColor: linkColors[linkType] }}
                  ></div>
                  <span>{linkType}</span>
                </div>
              ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LegendBar;
