import { Row, Col } from 'antd';
import React from 'react';

const Citation = () => {
  return (
    <Row>
      <Col sm={24} md={20} lg={16}>
        <ul className="citation-tab">
          <li>
            Muhammad Arif<sup>#</sup>, Cheng Zhang<sup>#</sup>, Xiangyu Li, Cem
            Güngör, Buğra Çakmak, Metin Arslantürk, Abdellah Tebani, Berkay
            Özcan, Oğuzhan Subaş, Wenyu Zhou, Brian Piening, Hasan Turkez, Linn
            Fagerberg, Nathan Price, Leroy Hood, Michael Snyder, Jens Nielsen,
            Mathias Uhlen, Adil Mardinoglu, (2021), iNetModels 2.0: an
            interactive visualization and database of multi-omics data, Nucleic
            Acids Research.{' '}
            <a
              href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkab254/6225230"
              target="_blank"
              rel="noopener noreferrer"
            >
              LINK
            </a>
          </li>
          <li>
            Sunjae Lee<sup>#</sup>, Cheng Zhang<sup>#</sup>, Muhammad Arif
            <sup>#</sup>, Rui Benfeitas, Gholamreza Bidkhori, Zhengtao Liu, Rui
            Benfetias, Gholamreza Bidkhori, Sumit Deshmukh, Mohamed Al Shobky,
            Alen Lovric, Jan Boren, Jens Nielsen, Mathias Uhlen, Adil
            Mardinoglu, (2017), TCSBN: a database of tissue and cancer specific
            biological networks, Nucleic Acids Research.{' '}
            <a
              href="https://academic.oup.com/nar/article/doi
/10.1093/nar/gkx994/4563312/TCSBN-a-database-of-tissue-and-cancer-specific?guestAccessKey=fc04bec9-9c98-410d-9103-e98cdc82ff77"
              target="_blank"
              rel="noopener noreferrer"
            >
              LINK
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  );
};

export default Citation;
