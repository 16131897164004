import { LinkWithNodes, Node } from '../utils/types';

const layoutDefaultState: { nodes: Node[]; links: LinkWithNodes[] } = {
  nodes: [],
  links: [],
};

export default (state = layoutDefaultState, action: any) => {
  switch (action.type) {
    case 'graph-modals/ADD_NODE':
      return {
        ...state,
        nodes: state.nodes.some((node) => node.id === action.node.id)
          ? state.nodes
          : [...state.nodes, action.node],
      };
    case 'graph-modals/ADD_LINK':
      return {
        ...state,
        links: state.links.some(
          (link: any) =>
            link.source.id === action.link.source.id &&
            link.target.id === action.link.target.id
        )
          ? state.links
          : [...state.links, action.link],
      };
    case 'graph-modals/REMOVE_NODE':
      return {
        ...state,
        nodes: state.nodes.filter((node: any) => node.id !== action.nodeId),
      };
    case 'graph-modals/REMOVE_LINK':
      return {
        ...state,
        links: state.links.filter(
          (link: any) =>
            link.source.id !== action.sourceId ||
            link.target.id !== action.targetId
        ),
      };

    case 'graph-modals/RESET_MODALS':
      return {
        ...state,
        nodes: [],
        links: [],
      };
    default:
      return state;
  }
};
