import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import dataTypesReducer from '../reducers/data-types';
import queryReducer from '../reducers/query';
import graphModalsReducer from '../reducers/graph-modals';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      dataTypes: dataTypesReducer,
      query: queryReducer,
      graphModals: graphModalsReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
