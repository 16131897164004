import React, { useRef, useState } from 'react';
import { Select, Table } from 'antd';
import {
  Dispatch,
  LinkWithNodes,
  QueryResponse,
  store,
} from '../../utils/types';
import { connect } from 'react-redux';
import { analyteColumnCreator, EDGES } from '../../utils/table-columns';
import { fieldExplanationMap } from '../../utils/helpers';

const { Option } = Select;

type Props = {
  tableType: string;
  queryResponse: QueryResponse;
  isClustering: boolean;
};
const TableView = ({ tableType, queryResponse, isClustering }: Props) => {
  const [currentTable, setCurrentTable] = useState('ALL');
  const searchInputRef = useRef<any>(null);
  const [searchFilter, setSearchFilter] = useState({
    searchText: '',
    searchedColumn: '',
  });

  return (
    <>
      {tableType === 'EDGES' ? (
        <Table
          rowKey={(link: LinkWithNodes) =>
            link.source.id + '-' + link.target.id
          }
          columns={EDGES(searchFilter, setSearchFilter, searchInputRef)}
          dataSource={queryResponse.links as LinkWithNodes[]}
        />
      ) : (
        <>
          <Select
            className="margin-top-10p margin-left-1r min-w-25r"
            value={currentTable}
            onChange={(val: string) => {
              setCurrentTable(val);
              setSearchFilter({ searchText: '', searchedColumn: '' });
            }}
          >
            {Object.keys(fieldExplanationMap).map((type) => (
              <Option key={type} value={type}>
                {type} Nodes
              </Option>
            ))}
          </Select>

          {fieldExplanationMap[currentTable] && (
            <div className="margin-top-20p">
              <Table
                rowKey="id"
                columns={analyteColumnCreator(
                  currentTable,
                  searchFilter,
                  setSearchFilter,
                  searchInputRef
                )}
                dataSource={
                  currentTable === 'ALL'
                    ? queryResponse.nodes
                    : queryResponse.nodes.filter(
                        (node) => node.location === currentTable
                      )
                }
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: store) => ({
  queryResponse: state.query.queryResponse,
  isClustering: state.query.requestedQueryDetails.clustering,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TableView);
